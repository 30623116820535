<template lang="">
<div>
UpdateDementiaKnowledge
</div>
</template>

<script>
export default {
    
    data() {
        return {

        }
    },
    methods: {

    },
    
    

}
</script>

<style lang="">

</style>
